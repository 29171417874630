import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CmsApiService } from './api/cms-api.service';

@Injectable({
  providedIn: 'root'
})
export class CacheControlService {

  /**
   * Cache observable
   */
  private _cache$: Observable<any> = this._cmsApi.getCacheCf().pipe(
    shareReplay(1),
  );

  private _cacheAuth$: Observable<any>;

  constructor(
    private _cmsApi: CmsApiService
  ) { }

  get cache$() {
    return this._cache$;
  }

  get cacheAuth$() {
    return this._cacheAuth$;
  }

  /**
   * Update cache
   */
  public updateCacheAuth() {
    this._cacheAuth$ = this._cmsApi.getCacheCf().pipe(
      shareReplay(1),
    );

    return this._cacheAuth$;
  }
}
